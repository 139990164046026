/*
 * Copyright by Intland Software
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information
 * of Intland Software. ("Confidential Information"). You
 * shall not disclose such Confidential Information and shall use
 * it only in accordance with the terms of the license agreement
 * you entered into with Intland.
 */
import { IFrameWidgets } from "./iframe-widgets";
// ensure that unit tests can find this marker
if (Symbol && self) {
    self[Symbol()] = 'typescript unit test marker';
}
var context = self;
context.codebeamer = context.codebeamer || {};
if (context.codebeamer.IFrameWidgets) {
    throw new Error('widget host is already loaded');
}
context.codebeamer.IFrameWidgets = new IFrameWidgets();
